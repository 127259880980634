import React from "react";
import { View, StyleSheet } from "react-native";

import { Typography, useTheme } from "@smartrent/ui";

interface InstructionStepProps {
  stepNumber?: number;
  title: React.ReactNode;
  imageUrl: string;
  imageTitle: string;
  instruction?: string;
}

export const InstructionStep = ({
  stepNumber,
  title,
  imageUrl,
  imageTitle,
  instruction,
}: InstructionStepProps) => {
  const { colors } = useTheme();

  return (
    <View style={styles.instructionStep}>
      <View style={styles.instructionTitleContainer}>
        {stepNumber ? (
          <View
            style={[
              styles.instructionStepNumberContainer,
              { backgroundColor: colors.primary },
            ]}
          >
            <Typography type="title" font="bold" color="textTitleInverse">
              {stepNumber}
            </Typography>
          </View>
        ) : null}

        <Typography
          type="title"
          font="bold"
          style={[
            styles.instructionTitleText,
            !stepNumber && styles.instructionTitleTextCentered,
          ]}
        >
          {title}
        </Typography>
      </View>

      <img
        src={imageUrl}
        alt={imageTitle}
        style={{ maxWidth: "95%", marginBottom: 16 }}
      />

      {instruction ? <Typography>{instruction}</Typography> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  instructionStep: {
    marginBottom: 16,
  },
  instructionTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    padding: 32,
  },
  instructionStepNumberContainer: {
    width: 51,
    height: 51,
    justifyContent: "center",
    alignItems: "center",
    marginRight: 16,
  },
  instructionTitleText: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  instructionTitleTextCentered: {
    justifyContent: "center",
  },
});
