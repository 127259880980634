import { initSentry } from "./lib/sentry";
import { setIdleSessionTimeout } from "./lib/idle-timeout";

import "../scss/app.scss";
require("phoenix_html");

initSentry();
setIdleSessionTimeout(null);

require("./react/routers/unauthenticated");
