import { View, Image, StyleSheet } from "react-native";

import { Typography } from "@smartrent/ui";

export interface TopPropertyImageProps {
  imageUrl: string | null;
  title: string;
  subtext?: string;
  theme?: "default" | "light";
}

export const TopPropertyImage = ({
  imageUrl = null,
  subtext,
  theme = "default",
  title,
}: TopPropertyImageProps) => {
  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        {imageUrl ? (
          <Image source={{ uri: imageUrl }} style={styles.image} />
        ) : null}
      </View>
      <View style={styles.textContainer}>
        <Typography
          color={theme === "default" ? "primary" : "white"}
          variation="semibold.title.six"
        >
          {title}
        </Typography>
        {subtext !== null && subtext !== undefined ? (
          <Typography
            variation="regular.body.small"
            color={theme === "default" ? "primary" : "textTitleInverse"}
          >
            {subtext || " "}
          </Typography>
        ) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    height: 79,
    width: "100%",
  },
  imageContainer: {
    width: 120,
    height: 79,
    marginRight: 16,
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
  },
  image: {
    width: "100%",
    height: "100%",
  },
});
