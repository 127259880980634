import { Link, Spacer, Typography, useTheme, VStack } from "@smartrent/ui";

import { StyleSheet } from "react-native";
import { useMedia } from "react-use";

import * as links from "@/lib/links";
import { RESTRUCTURE_BREAKPOINT } from "@/react/common/page_elements";
import { SectionTitle } from "@/react/common/page_elements/side_nav/SectionTitle";
import { GroupNavLink } from "@/react/common/page_elements/side_nav/GroupNavLink";
import { ManagerNavLinks } from "@/react/common/page_elements/side_nav/ManagerNavLinks";
import { AdminNavLinks } from "@/react/common/page_elements/side_nav/AdminNavLinks";

const SIDEBAR_WIDTH = 250;

export const Sidebar = () => {
  const { colors, spacing } = useTheme();
  const isMobile = useMedia(RESTRUCTURE_BREAKPOINT);

  return (
    <VStack
      spacing={spacing.xl}
      style={[
        isMobile ? styles.mobile : styles.desktop,
        { backgroundColor: colors.primary },
      ]}
    >
      <VStack spacing={spacing.xl}>
        <Logo />
        <VStack>
          <SectionTitle>Community</SectionTitle>
          <GroupNavLink />
        </VStack>
        <ManagerNavLinks />
      </VStack>
      <VStack>
        <SectionTitle>Admin</SectionTitle>
        <AdminNavLinks />
        <Spacer height={spacing.xl} />
        <Link
          // TODO: What's the href here?
          href="#"
          style={[
            styles.leaveFeedback,
            {
              padding: spacing.sm,
              borderTopColor: colors.gray600,
            },
          ]}
        >
          <Typography
            variation="regular.caption.large"
            color="textPrimaryInverse"
          >
            Leave Feedback
          </Typography>
        </Link>
      </VStack>
    </VStack>
  );
};

const Logo = () => {
  const { spacing } = useTheme();
  return (
    <Link
      to={links.home}
      style={{ paddingHorizontal: spacing.xxl, paddingTop: spacing.sm }}
    >
      <img src="/images/brandmark.svg" alt="SmartRent" />
    </Link>
  );
};

const styles = StyleSheet.create({
  mobile: {
    display: "none",
  },
  desktop: {
    width: SIDEBAR_WIDTH,
    zIndex: 2,
    position: "fixed" as any,
    height: "100%",
    justifyContent: "space-between",
    overflowY: "auto",
  },
  leaveFeedback: {
    display: "flex",
    justifyContent: "center",
    borderTopWidth: 1,
  },
});
