import { View, StyleSheet } from "react-native";
import { useFormikContext } from "formik";

import {
  Box,
  FormikSubmit,
  FormikTextInputField,
  Link,
  Typography,
  useTheme,
  VStack,
} from "@smartrent/ui";
import { ChevronLeft } from "@smartrent/icons";

import { useFocus } from "@/react/hooks/useFocus";

const TfaForm = () => {
  const { handleSubmit } = useFormikContext();
  const focusRef = useFocus();
  const { colors } = useTheme();

  return (
    <>
      <Box>
        <VStack spacing={16}>
          <View>
            <Typography style={styles.typography} type="title3">
              Two Factor Authorization
            </Typography>
            <Typography type="caption">
              You can use a recovery code or your six-digit two-factor
              authentication code
            </Typography>
          </View>
          <FormikTextInputField
            name="tfa"
            label="Six-Digit Code"
            textInputProps={{
              onSubmitEditing: () => handleSubmit(),
              textContentType: "oneTimeCode",
              // this is an android-only prop in react-native, but react-native-web
              // repurposes it for the autocomplete attribute, and thus the cast to any
              autoComplete: "one-time-code" as any,
            }}
            required
            ref={focusRef}
          />
          <FormikSubmit label="Sign In" submittingLabel="Submitting..." />
        </VStack>
      </Box>
      <View style={styles.startOverLink}>
        <Link href="/login">
          <View style={styles.startOverChevron}>
            <ChevronLeft size={24} color={colors.primaryInverse} />
            <Typography
              variation="semibold.caption.large"
              style={styles.startOverText}
              color="primaryInverse"
            >
              Start Over
            </Typography>
          </View>
        </Link>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  typography: {
    textAlign: "center",
    marginBottom: 4,
  },
  startOverChevron: { flexDirection: "row", alignItems: "center" },
  startOverLink: { marginTop: 16 },
  startOverText: { marginLeft: 4 },
});

export default TfaForm;
