import { Pressable, View, StyleSheet } from "react-native";
import {
  HStack,
  Menu,
  MenuOption,
  Typography,
  useTheme,
  VStack,
} from "@smartrent/ui";
import { useMenu } from "@smartrent/hooks";
import { Exit, KabobSolid, Key, Pencil, ShieldSecure } from "@smartrent/icons";

import * as links from "@/lib/links";
import { useCurrentUser } from "@/react/context/mgmt-api/user";
import { logout } from "@/react/queries/mgmt-api/auth";

const options: MenuOption[] = [
  {
    label: "My Access",
    title: "access",
    StartAdornment: Key,
    to: links.organization.myAccess(),
  },
  {
    label: "Edit Profile",
    title: "profile",
    StartAdornment: Pencil,
    to: links.profile.accountProfile(),
  },
  {
    label: "Change Password",
    StartAdornment: KabobSolid,
    to: links.profile.accountChangePassword(),
  },
  {
    label: "Security",
    StartAdornment: ShieldSecure,
    to: links.profile.accountSecurity(),
  },
  {
    label: "Log Out",
    StartAdornment: Exit,
    onPress: logout,
  },
];

export const ProfileMenu = () => {
  const { colors, spacing } = useTheme();
  const { menuProps, buttonProps } = useMenu();
  const { firstName, lastName, role } = useUserInfo();

  return (
    <View>
      <Pressable {...buttonProps}>
        <Avatar />
      </Pressable>

      <Menu
        minWidth={300}
        header={
          <HStack
            spacing={spacing.sm}
            align="center"
            style={[
              styles.menuHeader,
              {
                borderBottomColor: colors.border,
                paddingBottom: spacing.lg,
                marginBottom: spacing.sm,
              },
            ]}
          >
            <Avatar />
            <VStack>
              <Typography variation="semibold.body.medium">
                {firstName} {lastName}
              </Typography>
              <Typography color="gray600" variation="regular.caption.large">
                {role}
              </Typography>
            </VStack>
          </HStack>
        }
        options={options}
        {...menuProps}
      />
    </View>
  );
};

const Avatar = () => {
  const { colors } = useTheme();
  const { firstName, lastName } = useUserInfo();
  const firstInitial = firstName.charAt(0).toUpperCase();
  const lastInitial = lastName.charAt(0).toUpperCase();
  const userInitials = `${firstInitial}${lastInitial}`;

  return (
    <HStack
      align="center"
      justify="center"
      style={[
        styles.avatar,
        {
          backgroundColor: colors.primary,
          borderRadius: 40 / 2,
        },
      ]}
    >
      <Typography
        style={{ margin: "auto" }}
        color="textPrimaryInverse"
        variation="semibold.caption.large"
      >
        {userInitials}
      </Typography>
    </HStack>
  );
};

const useUserInfo = () => {
  const user = useCurrentUser();
  return {
    firstName: user.first_name,
    lastName: user.last_name,
    role: user.employee_role.name,
  };
};

const styles = StyleSheet.create({
  avatar: {
    width: 40,
    height: 40,
    textTransform: "uppercase",
  },
  menuHeader: {
    borderBottomWidth: 1,
  },
});
