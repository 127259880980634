import { useOrganization } from "@/react/queries/mgmt-api/organization";
import { useFeatureFlagEnabled } from "@/react/queries/mgmt-api/feature-flags";

export const useIsNewSidebarEnabled = () => {
  const { data: organization } = useOrganization();
  const { data: featureFlag } = useFeatureFlagEnabled({
    flag: "smrt_one_unified_design",
    organizationId: organization?.id,
  });
  return featureFlag.enabled;
};

export const useSidebarWidth = () => {
  const isNewSidebarEnabled = useIsNewSidebarEnabled();
  return isNewSidebarEnabled ? 250 : 300;
};
