import React from "react";
import { View, StyleSheet } from "react-native";
import { useMedia } from "react-use";
import { Button, Menu, useTheme } from "@smartrent/ui";

import { ChevronDown } from "@smartrent/icons";

import { useMenu } from "@smartrent/hooks";

import { NavLink } from "@/react/common/NavLink";
import { NavLinkProps } from "@/react/types";

import { RESTRUCTURE_BREAKPOINT } from "./ReactPage";

export const SIDEBAR_WIDTH_LEGACY = 300;

type SidebarColors = "default" | "primary";
export interface SidebarProps {
  header?: React.ReactNode;
  navItems?: NavLinkProps[];
  backButton?: React.ReactNode;
  color?: SidebarColors;
}

export const SidebarLegacy = ({
  header,
  navItems,
  backButton,
  color = "default",
}: SidebarProps) => {
  const { colors } = useTheme();
  const isMobile = useMedia(RESTRUCTURE_BREAKPOINT);

  const { responsiveStyles, headerStyles, headerBorderColor, backgroundColor } =
    {
      responsiveStyles: isMobile ? styles.mobile : styles.desktop,
      headerStyles: isMobile ? styles.headerMobile : styles.headerDesktop,
      headerBorderColor:
        color === "primary" ? colors.tertiary : colors.borderLow,
      backgroundColor: {
        backgroundColor:
          color === "primary" ? colors.primary : colors.pageBackground,
      },
    };

  return (
    <View
      style={[
        styles.container,
        responsiveStyles,
        backgroundColor,
        { borderColor: colors.borderLow },
      ]}
    >
      <View style={[headerStyles, { borderColor: headerBorderColor }]}>
        {header}
      </View>
      {isMobile ? (
        <MobileNav navItems={navItems || []} />
      ) : (
        <DesktopNav
          navItems={navItems || []}
          color={color}
          backButton={backButton}
        />
      )}
    </View>
  );
};

type DesktopNavProps = {
  navItems: NavLinkProps[];
  backButton?: React.ReactNode;
  color?: SidebarColors;
};

const DesktopNav = ({ navItems, color, backButton }: DesktopNavProps) => {
  return (
    <View style={styles.nav}>
      {backButton ? (
        <View style={styles.backButtonContainer}>{backButton}</View>
      ) : null}
      {navItems.map((item) => (
        <NavLink
          {...item}
          color={color === "default" ? "default" : "light"}
          key={item.title}
        />
      ))}
    </View>
  );
};

type MobileNavProps = { navItems: NavLinkProps[] };

const MobileNav = ({ navItems }: MobileNavProps) => {
  const { colors } = useTheme();
  const { buttonProps, menuProps } = useMenu();
  const activeItem = navItems?.find((item) => item.isActive);

  return (
    <View style={[styles.nav, styles.navMobile]}>
      <Button {...buttonProps} iconRight={ChevronDown} variation="plain">
        {activeItem?.title}
      </Button>
      <Menu
        width={300}
        options={navItems.map((item) => ({
          label: item.title,
          to: item.route,
          color: item.isActive ? colors.primary : colors.black,
        }))}
        {...menuProps}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  headerMobile: {
    flexGrow: 1,
  },
  headerDesktop: {
    width: "100%",
    borderBottomWidth: 1,
  },
  mobile: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: "auto",
    borderBottomWidth: 1,
  },
  desktop: {
    position: "fixed" as any,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: SIDEBAR_WIDTH_LEGACY,
    flexBasis: SIDEBAR_WIDTH_LEGACY,
    borderRightWidth: 1,
  },
  container: {
    height: "100%",
    zIndex: 2,
    padding: 0,
  },
  nav: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "auto",
    width: "100%",
    height: "auto",
  },
  navMobile: {
    width: "auto",
    marginLeft: "auto",
    marginRight: 8,
  },
  backButtonContainer: {
    padding: 8,
    marginBottom: 8,
  },
});
