import { Pressable, StyleSheet, View } from "react-native";
import { useLocation } from "react-router-dom";

import {
  Button,
  HStack,
  Spacer,
  Stack,
  Typography,
  useTheme,
} from "@smartrent/ui";
import { CloudSync, Search } from "@smartrent/icons";
import { useMedia } from "react-use";

import {
  GlobalSearch,
  useGlobalSearch,
} from "@/react/common/GlobalSearch/GlobalSearch";
import { RESTRUCTURE_BREAKPOINT } from "@/react/common/page_elements/ReactPage";
import { ProfileMenu } from "@/react/common/page_elements/top_nav/ProfileMenu";

// TODO: Sync button (wire up to API)
// TODO: Contextual button (replaces floating action button)
export const TopNav = () => {
  const { colors, spacing } = useTheme();
  const isMobile = useMedia(RESTRUCTURE_BREAKPOINT);

  return (
    <Stack
      align="center"
      justify="space-between"
      direction={isMobile ? "column-reverse" : "row"}
      style={[styles.topNav, { borderBottomColor: colors.border }]}
      wrap
    >
      <View>
        <Typography color="textPrimary" variation="bold.title.four">
          Breadcrumbs
        </Typography>
      </View>
      <HStack spacing={spacing.xl} align="center">
        <GlobalSearchTrigger />
        <SyncButton />
        <ProfileMenu />
      </HStack>
    </Stack>
  );
};

const GlobalSearchTrigger = () => {
  const { colors, rounding, spacing } = useTheme();
  const location = useLocation();
  const globalSearchProps = useGlobalSearch();
  const showSearchBox =
    location.pathname.includes("/groups/") ||
    location.pathname.includes("/units/");

  if (!showSearchBox) {
    return null;
  }

  return (
    <Pressable
      style={{
        backgroundColor: colors.gray050,
        borderColor: colors.border,
        borderWidth: 1,
        flexDirection: "row",
        alignItems: "center",
        borderRadius: rounding.raisedSurface,
        height: 40,
        width: 250,
        paddingHorizontal: 24,
      }}
      onPress={globalSearchProps.onOpen}
    >
      <Search />
      <Spacer width={spacing.xxs} />
      <Typography color="gray600" variation="semibold.body.medium">
        Search...
      </Typography>
      <GlobalSearch {...globalSearchProps} />
    </Pressable>
  );
};

// TODO: Wire this up to API
const SyncButton = () => {
  const { colors, spacing } = useTheme();
  const isMobile = useMedia(RESTRUCTURE_BREAKPOINT);

  if (isMobile) {
    return null;
  }

  return (
    <Button variation="plain" style={{ padding: spacing.xxs }}>
      <CloudSync color={colors.primary} size={32} />
    </Button>
  );
};

const styles = StyleSheet.create({
  topNav: {
    paddingVertical: 20,
    paddingHorizontal: 24,
    borderBottomWidth: 1,
  },
});
